import _ from 'lodash';
import React, { memo, useMemo } from 'react';
import { ArrowIcon, Box, Col, colors, fontFamilies, H1, IconButton, InputStepper, Label, LabeledInput, Row, Text, Toggle, ToggleLink } from '@commonsku/styles';
import { useSelector } from 'react-redux';

const SelectPlanForm = memo((props) => {
  const {
    form,
    updateField,
    onContinue,
    feature,
    plans
  } = props;

  const selectedPlan = plans.find((plan) => (feature.stripe_plan_name === plan.product.name && form.payment_model.toLowerCase() === plan.interval))
  const currency = selectedPlan.currency.toUpperCase();
  const planAmount = (selectedPlan.amount / 100).toLocaleString("en-US", { style: "currency", currency });

  const canContinue = useMemo(() => {
    return form.quantity && form.quantity > 1;
  }, [form.quantity]);

  const handleContinue = () => {
    if (!canContinue) { return; }
    onContinue();
  };

  return (
    <Row>
      <Col xs style={{ paddingBottom: 32, }}>
        <H1>Licenses</H1>
      </Col>
      <Col xs style={{ textAlign: 'center' }}>
        <Box style={{ background: colors.primary1['20'], height: feature.feature_name === 'FULL' ? 145 : 180 }}>
          <Row pt={25} pb={25}>
            <Col xs style={{borderTopRightRadius: '5px', borderTopLeftRadius: '5px',}}>
              {feature.feature_name !== 'FULL' &&
                <Text as="div" style={{ margin: 0, fontSize: '1.5rem', color: colors.primary1['85'] }}>{feature.feature_description}</Text>
              }
              <Text as="span" style={{ margin: 0, fontSize: '2rem', color: colors.primary1['85'] }}>{planAmount}</Text>
              <Text as="span" style={{ margin: 0, fontSize: '1rem', color: colors.primary1['85'], verticalAlign: 'baseline', }}>{currency}</Text>
            </Col>
            <Col xs style={{ paddingBottom: 20, }}>
              <Text as="p" style={{ margin: 0, fontSize: '1rem', color: colors.primary1['85'], fontFamily: fontFamilies.regular }}>
                per user/{form.payment_model.toLowerCase()}
              </Text>
              <Text as="p" style={{ margin: 0, fontSize: '1rem', color: colors.primary1['85'], fontFamily: fontFamilies.regular }}>
                (prorated for the first month)
              </Text>
            </Col>
          </Row>
        </Box>
      </Col>
      <Col xs={8} sm={8} md={6} mt={30}>
        <Label>
          Number of licenses (minimum 2)
        </Label>
        <InputStepper
          initialValue={2}
          max={999}
          min={2}
          onChange={value => updateField('quantity', value)}
        />
        {feature.feature_name !== 'FULL' && <>
          <Label mt={30}>
            Payment
          </Label>
          <Toggle size="medium">
            <ToggleLink onClick={() => updateField('payment_model', 'YEAR')} selected={form.payment_model === 'YEAR'}>Annual (10% discount)</ToggleLink>
            <ToggleLink onClick={() => updateField('payment_model', 'MONTH')} selected={form.payment_model === 'MONTH'}>Monthly</ToggleLink>
          </Toggle>
        </>}
      </Col>
      <Col xs mt={40}>
        <IconButton
          variant={'cta'}
          onClick={handleContinue}
          Icon={<ArrowIcon direction='right' />}
          iconPosition='right'
        >Continue</IconButton>
      </Col>
    </Row>
  );
});

export default SelectPlanForm;
