import React from 'react';
import { connect } from 'react-redux';
import { ErrorBoundary, Theme, themeOptions, GlobalStyle as BaseGlobalStyle } from '@commonsku/styles';
import withReducers from '../store/withReducers';
import Overlay from '../components/Overlay';
import createPopup from '../popup-factory';
import { getPopups } from '../selectors';
import ActivateCompany from '../components/company-activation';
import { SKUFontDefinitionStyles } from '../components/helpers/createGlobalStyle';

const additionalGlobalStyle = (p) => `
${SKUFontDefinitionStyles}
@media only screen and (min-width: 0px) {
  .csku-styles.page {
    min-height: 100%;
  }
  .activate-company-box {
    height: 100%;
  }
}

@media only screen and (min-width: 768px) and (min-height: 900px) {
  .csku-styles.page {
    height: 100%;
  }
}

@media only screen and (min-width: 864px) {
  .activate-company-box {
    height: 880px;
  }
}
`;

export const GlobalStyle = (p) =>
  <BaseGlobalStyle {...p} additionalStyles={additionalGlobalStyle} />;

function CompanyActivationApp({
  popups,
  ...props
}) {
  return (
    <Theme theme={themeOptions}>
      <GlobalStyle theme={themeOptions} />
      <ErrorBoundary>
        <div style={{ height: '100%' }}>
          <ActivateCompany dashboard_type={props.dashboard_type} />
          <div className="resku">
            {popups ? popups.map((p, idx) => createPopup(p, idx, props)) : null}
          </div>
        </div>
        <div className="resku">
          <Overlay popups={popups} />
        </div>
      </ErrorBoundary>
    </Theme>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    identity: state.identity,
    popups: getPopups(state),
    filterOptions: {},
    loading: state.display.loading,
    dashboard_type: state.entities.dashboard_type,
  };
};

export default withReducers(connect(mapStateToProps, null)(CompanyActivationApp), {}, true);
