import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import URI from 'urijs';
import { Col, colors, H5, Page, Row } from '@commonsku/styles';
import { useIdentity } from '../../hooks';
import { oauth } from '../../utils';
import CompanyAddressForm from './CompanyAddressForm';
import SelectPlanForm from './SelectPlanForm';
import Card from './Card';
import companyGeneralDataReducer from '../../redux/companyGeneralData';
import useCompanyGeneralDataById from '../../hooks/useCompanyGeneralDataById';
import withReducers from '../../store/withReducers';
import featuresReducer from "../../redux/features";
import useFeatures from '../../hooks/useFeatures';
import { toast, ToastContainer } from 'react-toastify';
import '../../../scss/css/react-toastify.css';
import { STRIPE_BILLING_ADDRESS_INVALID } from '../../geo';

const addressSelector = createSelector(
  s => s.entities.addresses || {},
  s => _.first(_.values(s))
);
const stripeInfoSelector = createSelector(
  s => s.entities.stripe_info,
  s => s
);

function ActivateCompanyPage() {
  const identity = useIdentity();
  const { company: distributor, save: saveDistributor } = useCompanyGeneralDataById(identity.company_id, identity.company_type);
  const stripeInfoData = useSelector(stripeInfoSelector);
  const address = useSelector(addressSelector);
  const [features] = useFeatures(identity.company_type)
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [stripeInfo, setStripeInfo] = useState();
  const [form, setForm] = useState({
    address_line_1: '',
    address_line_2: '',
    address_city: '',
    address_country: '',
    address_state: '',
    address_postal: '',
    quantity: 2,
    payment_model: '',
  });
  const feature = features.find((f) => f.feature_name === identity.features);

  const renderStripeInfo = useCallback(() => {
    function reloadStripeInfo() {
      oauth('GET', 'stripe', {})
        .then(({ json }) => {
          if (!json.stripe_info) { return; }
          setStripeInfo(json.stripe_info);
        });
    }
    const uri = new URI();
    const params = uri.search(true);
    let formStep = parseInt(params.step || 1);
    if (_.isEmpty(stripeInfo)) {
      setStep(formStep);
      return reloadStripeInfo();
    }
    if (!stripeInfo.billing_address) {
      formStep = 1;
    } else {
      formStep = _.max([formStep, 2]);
    }
    const sessions = _.get(stripeInfo, 'sessions');
    if (_.isEmpty(sessions)) {
      formStep = _.min([formStep, 2]);
    } else {
      formStep = _.max([formStep, 2]);
      const isSessionCompleted = _.find(sessions, (s) => (['SUCCESS', 'COMPLETE'].indexOf(s.status) > -1));
      if (!isSessionCompleted) {
        setLoading(true);
        setStep(formStep);
        if (formStep === 4) {
          return reloadStripeInfo();
        }
        return;
      }
    }
    const quantity = _.get(stripeInfo, 'stripe_subscription_item.quantity', 0);
    if (quantity < 1) {
      formStep = _.min([formStep, 2]);
    } else {
      formStep = 4;
    }
    setStep(formStep);
    if (formStep === 4) {
      setLoading(true);
      window.location.href = '/redirect.php';
    } else {
      setLoading(false);
    }
  }, [stripeInfo]);

  const updateField = useCallback(
    (field, value) => setForm(s => ({ ...s, [field]: value })),
    []
  );

  const onSubmit = useCallback(() => {
    if (parseInt(_.get(form, ['quantity'], 0)) < 1) {
      return;
    }
    setLoading(true);

    saveDistributor({
      payment_model: form.payment_model
    }, false);

    const data = {
      action: 'create-setup-with-quantity-portal',
      quantity: form.quantity,
    };

    oauth('POST', 'stripe', data)
      .then(({ json }) => {
        const url = _.get(json, 'session.url');
        if (url) {
          window.location.href = url;
        } else if (json.error) {
          setStep(1);
          setLoading(false);
          const errorMessage = json.errorCode === STRIPE_BILLING_ADDRESS_INVALID ?
            'This address cannot be validated, please update it' :
            'An error occurred, please contact support';

          toast.error(errorMessage);
        }
      });
  }, [form]);

  useEffect(() => {
    if (!address) { return; }
    setForm(s => ({ ...s, ...address }));
  }, [address]);

  useEffect(() => {
    if (!stripeInfoData) { return; }
    setStripeInfo(stripeInfoData);
  }, [stripeInfoData]);

  useEffect(() => {
    renderStripeInfo();
  }, [renderStripeInfo]);

  useEffect(() => {
    setForm(s => ({
      ...s,
      payment_model: distributor.payment_model
    }))
  }, [distributor]);

  return (
    <Page className="csku-styles page" style={{ height: '100%', background: colors.neutrals['20'] }}>
      <Row style={{ height: '100%' }}>
        <Col xs style={{
          ...(!loading ? { display: 'none' } : {}),
        }}>
          <Card company_name={identity.company_name} step={step === 3 ? 2 : (step+1)}>
            <Row>
              <Col xs center style={{ paddingTop: 20, }}>
                <H5>Setting up your commonsku account...</H5>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs style={{
          ...(loading ? { display: 'none' } : {}),
          ...(step !== 1 ? { display: 'none' } : {}),
        }}>
          <Card company_name={identity.company_name} step={2}>
            <CompanyAddressForm
              form={form}
              updateField={updateField}
              onContinue={() => setStep(2)}
            />
          </Card>
        </Col>
        <Col xs style={{
          ...(loading ? { display: 'none' } : {}),
          ...(step !== 2 && step !== 3 ? { display: 'none' } : {}),
          margin: 'auto',
        }}>
          <Card company_name={identity.company_name} step={3}>
            {feature && stripeInfo && form.payment_model &&
              <SelectPlanForm
                form={form}
                updateField={updateField}
                onContinue={onSubmit}
                feature={feature}
                plans={stripeInfo.stripe_plans}
              />
            }
          </Card>
        </Col>
      </Row>
      <ToastContainer
          autoClose={false}
          hideProgressBar={true}
      />
    </Page>
  );
}


export default withReducers(ActivateCompanyPage, {
  companyGeneralDataById: companyGeneralDataReducer,
  features: featuresReducer,
});
