import { useEffect } from 'react';
import { selectors, getAll } from '../redux/features';
import { useSelector, useDispatch } from 'react-redux';

export default function useFeatures(companyType: string) {
    const dispatch = useDispatch();
    const reduxState = useSelector((state: any) => {
        return state.features
    });
    const items = selectors.selectAll(reduxState);
    const loading = reduxState.loading;

    useEffect(() => {
        dispatch(getAll(companyType));
    }, [dispatch, companyType]);

    return [
        items,
        loading,
    ];
};
