import {
  createEntityAdapter, createSlice
} from '@reduxjs/toolkit';
import { oauth } from '../utils';

interface Feature {
  feature_id: string;
  feature_name: string;
  feature_description: string;
}
/**
* The entity adapter
*/
const adapter = createEntityAdapter({
  selectId: (feature: Feature) => feature.feature_id,
});

const slice = createSlice({
  name: 'features',
  initialState: adapter.getInitialState({
      loading: 'idle'
  }),
  reducers: {
      loading(state, action) {
          if (state.loading === 'idle') {
              state.loading = 'pending';
          }
      },
      loaded(state, action) {
          if (state.loading === 'pending') {
              state.loading = 'idle';
          }
      },
      received(state, action) {
          if (state.loading === 'pending') {
              adapter.setAll(state, action.payload);
              state.loading = 'idle';
          }
      },
  }
});

export const {
  loading,
  loaded,
  received,
} = slice.actions;

const baseUrl = 'feature';

/**
* The actions (thunk)
*/
export const getAll = (companyType: string) => async (dispatch, getState) => {
  const state = getState();
  dispatch(loading(state));
  let data = [];
  if (companyType) {
    const res = await oauth('GET', baseUrl, { company_type: companyType });
    dispatch(received(res.json.features));
    data = res.json;
  }
  dispatch(loaded(state));
  return data;
};

/**
* The selectors
*/
export const selectors = adapter.getSelectors();

export default slice.reducer;

